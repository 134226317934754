// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Layzr from "layzr.js";

// init Layzr
document.addEventListener("DOMContentLoaded", () => {
    const INSTANCE = Layzr();
    INSTANCE.update().check().handlers(true);
});
