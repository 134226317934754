// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

document.addEventListener("DOMContentLoaded", () => {
    const SEARCH_TOGGLE = document.querySelector("[data-toggle=header-search]");
    const SEARCH_FORM = document.querySelector("#header-search");
    const SEARCH_INPUT  = SEARCH_FORM ? SEARCH_FORM.querySelector("input[type=search]") : false;

    if (SEARCH_TOGGLE && SEARCH_FORM && SEARCH_INPUT) {
        SEARCH_TOGGLE.addEventListener("click", (e) => {
            e.preventDefault();

            SEARCH_FORM.classList.add("is-active");
            SEARCH_INPUT.focus();
        });

        SEARCH_INPUT.addEventListener("blur", () => {
            if (!SEARCH_INPUT.value) {
                SEARCH_FORM.classList.remove("is-active");
                SEARCH_TOGGLE.focus();
            }
        }, { passive: true });
    }
});
